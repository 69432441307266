import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame-reload"
export default class extends Controller {
  connect() {
    this.interval = setInterval(() => {
      this.element.reload();
    }, 2000);
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
