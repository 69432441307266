import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

// Connects to data-controller="timeline"
export default class extends Controller {
  connect() {
    new Sortable(this.element, {
      animation: 150,
      chosenClass: 'text-bg-info',
      onEnd: (evt) => this.resetPositions()
    });
  }

  resetPositions() {
    this.element.querySelectorAll('div.card input.position').forEach(
      (input, i) => input.value = i
    );
  }
}
